import React from "react"
import Helmet from "react-helmet"
import { StaticQuery } from "gatsby"
import Cookies from "universal-cookie"
import TransitionLink from "gatsby-plugin-transition-link/AniLink"
import * as int from "../interactions"
import Logo from "../images/logo.svg"
import logoMobile from "../images/mobile-logo.svg"
import Points from "../components/Points"
import Preloader from "../components/Preloader"
import Div100vh from "react-div-100vh"

import {
  showGreeting,
  hideGreetings,
  changeTextColor,
  pageTransitionExitLangSelect,
  //showLangSelect,
  hideGreetingTitle,
} from "../animations"

const cookies = new Cookies()

class LanguageSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.data.wordpressAcfOptions.options.lang_select,
      lastGreeting: "initial",
      hoveredOut: false,
      firstChange: true,
    }
  }

  checkCookie() {
    return cookies.get("lang") !== undefined ? cookies.get("lang") : false
  }

  componentDidMount() {
    if (this.checkCookie() !== false) {
      //navigate(`/${this.checkCookie()}/`)
    }

    //const element = document.querySelector(".js-greeting[data-lang='initial']")
    // showGreeting(element)
    //showLangSelect()
  }

  hideGreetings() {
    const element = document.querySelector(
      `.js-greeting[data-lang="${this.state.lastGreeting}"]`
    )
    const link = document.querySelector(
      `.js-lang-link[data-lang="${this.state.lastGreeting}"]`
    )
    changeTextColor(link, "#fff")
    // if (element.classList.contains("left__title--main")) {
    //
    // } else {
    if (this.state.firstChange === true) {
      hideGreetingTitle()
      this.setState({
        firstChange: false,
      })
    } else {
      hideGreetings(element, 0.5)
    }

    // }
  }

  showGreeting(e) {
    const lang = e.target.closest("a").dataset.lang

    this.hideGreetings()

    const element = document.querySelector(`.js-greeting[data-lang="${lang}"]`)

    changeTextColor(e.target.closest("a"), "#0681DA")

    showGreeting(element, 0.4)

    this.setState({
      lastGreeting: lang,
    })
    // }
  }

  showInitialGreenting(e) {
    this.setState({
      lastGreeting: "initial",
      // hoveredOut: true,
    })
    this.showGreeting(e, "initial")
    // this.hideGreetings()

    //showGreetingTitle()
  }

  render() {
    const content = this.state.content
    let langCounter = 0

    return (
      <>
        <Helmet title="NeoSoft AG" />
        <Div100vh id="lang-select">
          <div className="lang-select__logo">
            <div className="grid">
              <div className="lang-select__logo-container">
                <img src={logoMobile} className="md-show" alt="Neosoft logo." />
                <img src={Logo} className="md-hide" alt="Neosoft logo." />
                <div className="overlay" />
              </div>
            </div>
          </div>
          <div className="grid grid--full-height">
            <div className="lang-select__part lang-select__part--left">
              <div className="left__title-container">
                {content.languages.map(lang => {
                  langCounter++

                  return (
                    <h2
                      className="white left__title left__title--greeting js-greeting"
                      data-lang={lang.language}
                      key={`lang-${langCounter}`}
                    >
                      {lang.greeting}
                      <span className="overlay overlay--up" />
                      <span className="overlay overlay--down" />
                    </h2>
                  )
                })}
                <h2
                  className="white left__title left__title--main js-greeting"
                  data-lang="initial"
                >
                  {content.main_title}
                  <span className="overlay overlay--up" />
                  <span className="overlay overlay--down" />
                  <span className="overlay overlay--hide" />
                </h2>
                <div className="overlay overlay--title-container" />
              </div>
            </div>
            <div className="lang-select__part lang-select__part--right">
              <ul>
                {content.languages.map(lang => {
                  langCounter++
                  return (
                    <li key={`lang-${langCounter}`}>
                      <TransitionLink
                        timeout={2000}
                        className="js-lang-link"
                        to={`/${lang.language}/`}
                        data-lang={lang.language}
                        dangerouslySetInnerHTML={{ __html: lang.title }}
                        exit={pageTransitionExitLangSelect()}
                        //entry={pageTransitionEnter()}
                        onClick={e => int.setLangCookie(e)}
                        onMouseEnter={e => this.showGreeting(e)}
                        // onMouseLeave={e => this.showInitialGreenting(e)}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <Points location="lang-select" modifier="points--lang-select" />
          <div className="lang-select__separator">
            <div className="lang-select__separator__line" />
          </div>
        </Div100vh>
        <Preloader tpl="langSelect" />
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            lang_select {
              main_title
              languages {
                language
                title
                greeting
              }
            }
          }
        }
      }
    `}
    render={data => <LanguageSelect data={data} {...props} />}
  />
)
