import React from "react"
import Logo from "../images/logo.svg"
import logoMobile from "../images/mobile-logo.svg"
import { preloadFadeOut } from "../animations"
import Div100vh from "react-div-100vh"

class Preloader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tpl: this.props.tpl,
    }
  }

  formPoints() {
    const rowsArr = []
    let pointsCount = 1
    for (let i = 0; i < 5; i++) {
      const pointsArr = []
      rowsArr.push(pointsArr)
      for (let u = 0; u < 9; u++) {
        rowsArr[i].push(pointsCount)
        pointsCount++
      }
    }
    return rowsArr
  }

  componentDidMount() {
    preloadFadeOut(this.state.tpl)
  }

  render() {
    const points = this.formPoints()
    let rowCounter = 0
    return (
      <Div100vh id="preload" className="points points--preload">
        <div className="grid grid--points">
          {points.map(row => {
            rowCounter++
            return (
              <div className="points__row" key={`row-${rowCounter}`}>
                {row.map(point => {
                  return (
                    <div
                      className={`point point--preload point--${point}`}
                      key={`preload-${point}`}
                    />
                  )
                })}
              </div>
            )
          })}
          <div className="preload__logo">
            <img src={logoMobile} className="md-show" alt="Neosoft logo" />
            <img src={Logo} className="md-hide" alt="Neosoft logo" />
            <div className="overlay" />
          </div>
        </div>
      </Div100vh>
    )
  }
}

export default Preloader
